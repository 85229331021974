<template>
  <div class="table">
    <!-- 头部 -->
    <div class="crumbs">
      <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
    </div>
    <!-- 表格内容 -->
    <div class="container">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="签到活动名称:" label-width="220">
          <el-input v-model="queryParams.name" placeholder="请输入签到活动名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="queryParams.state" placeholder="全部" clearable @change="changeQuery">
            <el-option label=" 未开始" :value="1"></el-option>
            <el-option label="活动中" :value="2"></el-option>
            <el-option label="活动结束" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <div style="float: right">
          <el-button type="primary" @click="changeQuery()">搜索</el-button>
        </div>
      </el-form>
      <div class="action">
        <el-button type="primary" @click="add()">新建签到活动</el-button>

      </div>
      <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
        <el-table-column prop="id" label="id" align="center" width="100"></el-table-column>
        <el-table-column prop="name" label="签到活动名称" align="center" width="auto"></el-table-column>
        <el-table-column label="排序" prop="r" align="center" width="100"></el-table-column>
        <el-table-column prop="ctime" label="上线时间" width="200" align="center"></el-table-column>
        <el-table-column prop="etime" label="下线时间" width="200" align="center"></el-table-column>
        <el-table-column label="状态" prop="state" align="center" width="100">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="scope.row.state === 1">未开始</el-tag>
            <el-tag type="success" v-if="scope.row.state === 2">活动中</el-tag>
            <el-tag type="info" v-if="scope.row.state === 3">活动结束</el-tag>
          </template>
        </el-table-column>
          <el-table-column label="默认活动" prop="is_default" align="center" width="100">
              <template slot-scope="scope">
                  <el-tag type="primary" v-if="scope.row.is_default === 0">否</el-tag>
                  <el-tag type="success" v-if="scope.row.is_default === 1">是</el-tag>
              </template>
          </el-table-column>
        <el-table-column prop="product" label="签到活动奖品" width="160" align="center">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" @click="goProduct(scope.$index, scope.row)">签到活动奖品
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="100">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
            <el-button v-if="scope.row.state === 2 " type="text" class="red" @click="state(scope.row, 1)">下线
            </el-button>
            <el-button v-if="scope.row.state === 1 " type="text" class="green" @click="state(scope.row, 2)">
              上线</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
    </div>
  </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {request} from '@/common/request';
import {deal} from '@/common/main';

export default {
  name: 'seller',
  components: {
    Breadcrumb,
    Pagination
  },
  data() {
    return {

      defaultImg: require('../../assets/img/zhan.jpeg'),
      hearder: {},
      menuList: ['营销', '签到活动管理'],
      queryParams: {
        searchName: '',
        page: 1,
        pagesize: 10
      },
      positionList: [],
      loading: false,
      pageInfo: {},
      addVisible: false,
      title: '加载中',
      mode: 'add',
      form: {
        state: 1
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      }
    };
  },
  created() {
    this.hearder.token = localStorage.getItem('token');
    this.hearder.platform = 'admin';
    this.loadData(); // 这是我们获取数据的函数
  },
  activated() {
    this.loadData();
  },
  beforeRouteLeave(to, from, next) {
    // 设置下一个路由的 meta
    to.meta.keepAlive = false; // B 跳转到 A 时，让 A 不缓存，即刷新（代码写在B页面）
    next();
  },
  methods: {

    // 查看大图
    showBigImg(index, node) {
      this.$nextTick(function () {
        let shows = document.getElementsByClassName(`${node}column${index}`)
        let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
        show[0].style['display'] = 'block'
        let dom = show[0].getElementsByClassName('el-image-viewer__mask')
        dom[0].addEventListener('click', function () {
          show[0].style['display'] = 'none'
        })
      })
    },
    loadData() {
      this.loading = true;
      // 获取签到活动列表
      request.get('/activity/sign/activity/list', this.queryParams).then(ret => {
        if (ret.code === 1) {
          this.loading = false;
          this.pageInfo = ret.data;
          this.pageInfo.list = ret.data.list;
          this.pageInfo.list.map(function (val) {
            val.ctime = deal.timestamp(val.ctime, 'unix');
            val.etime = deal.timestamp(val.etime, 'unix');
            return val;
          })
        } else {
          this.$message.error(ret.msg);
        }
      });
    },
    // 签到活动商品
    goProduct(index, row) {
      this.$router.push({ path: '/activity-SignActivityGoodsList', query: { id: row.id } })
    },
    add() {
      this.$router.push({ path: '/activity-SignActivityAdd' })
    },
    edit(index, row) {
      this.title = '编辑';
      this.$router.push({ path: '/activity-SignActivityEdit', query: { id: row.id } })
    },
    changeQuery() {
      this.queryParams.page = 1;
      this.loadData();
    },
    refreshPageSize(val) {
      this.queryParams.pagesize = val;
      this.loadData();
    },
    refreshPageNum(val) {
      this.queryParams.page = val;
      this.loadData();
    },
    // 单个修改状态
    state(item, state) {
      let id = item.id;
      let param = Object.assign({}, item);
      param.state = state;
      param.start_day = deal.timestamp(param.start_day);
      param.end_day = deal.timestamp(param.end_day);
      request.post('/activity/sign/activity/edit', param).then(ret => {
        if (ret.code === 1) {
          this.$message.success('操作成功');
          this.loadData();
        } else {
          this.$message.error(ret.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.green {
  color: green;
}

.mr10 {
  margin-right: 10px;
}

.crop-demo-btn {
  position: absolute;
  right: 0px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #409eff;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  box-sizing: border-box;
  float: right;
}

.crop-input {
  position: absolute;
  width: 100px;
  height: 40px;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.pre-img {
  width: 100px;
  height: 100px;
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 5px;
  margin-top: 10px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  font-size: 28px;
  line-height: 100px;
}

/* .avatar-uploader .avatar-uploader-icon {
        line-height: 100px;
    } */

.cell {
  text-align: center;
}

.el-input--suffix .el-input__inner {
  padding-right: 15px;
}

.up {
  margin-top: 20px;
}
</style>
